import styles from './best-three-ways-odds.module.less';
import React from 'react';
import firstImage from 'static/1.png'
import secondImage from 'static/2.png'
import versusImage from 'static/X.png'
import LogoHelper from 'components/helpers/logo-helper';


const getTrend = bet => {
    if(bet === false){
      return <span className={styles.triangleDown}></span>
    }
    else if(bet === true){
      return <span className={styles.triangleUp}></span>
    }

    return <span className={styles.triangleFiller}></span>
  }

const BestThreeWaysOdds = props => {
  return (
    <div className={styles.mainContainer}>
        <div className={styles.childContainer}>
            <div className={styles.oddsBox}>
                <div className={styles.companyOddsBox}>
                    <div className={styles.threeWaysListImage}>
                        <img src={firstImage}></img>
                    </div>
                    <div>
                        <a href={(props.betLinks.find(bl => bl.id == `e:provider:${props.list.topBookieNameHomeId}`))?.url} className={styles.listItemUrl2way} target="_blank" rel="noopener noreferrer">
                            <img src={(props.betLinks.find(bl => bl.id == `e:provider:${props.list.topBookieNameHomeId}`))?.logo} alt="" className={styles.betLogo2way} />
                        </a>
                    </div>
                    <div className={styles.oddText}>
                        {getTrend(props.list.topHomeWinOddIncreased)}
                        { props?.list?.topHomeWinOdd?.toFixed(2)}
                    </div>
                </div>
                <div className={styles.companyOddsBox}>
                    <div className={styles.threeWaysListImage}>
                        <img src={versusImage}></img>
                    </div>
                    <div>
                        <a href={(props.betLinks.find(bl => bl.id == `e:provider:${props.list.topBookieNameId}`))?.url} className={styles.listItemUrl2way} target="_blank" rel="noopener noreferrer">
                            <img src={(props.betLinks.find(bl => bl.id == `e:provider:${props.list.topBookieNameId}`))?.logo} alt="" className={styles.betLogo2way} />
                        </a>
                    </div>
                    <div className={styles.oddText}>
                        {getTrend(props.list.topDrawOddIncreased)}
                        {props?.list?.topDrawOdd?.toFixed(2)}
                    </div>
                </div>
                <div className={styles.companyOddsBox}>
                    <div className={styles.threeWaysListImage}>
                        <img src={secondImage}></img>
                    </div>
                    <div>
                        <a href={(props.betLinks.find(bl => bl.id == `e:provider:${props.list.topBookieNameAwayId}`))?.url}className={styles.listItemUrl2way} target="_blank" rel="noopener noreferrer">
                            <img src={(props.betLinks.find(bl => bl.id == `e:provider:${props.list.topBookieNameAwayId}`))?.logo} alt="" className={styles.betLogo2way} />
                        </a>
                    </div>
                    <div className={styles.oddText}>
                        {getTrend(props.list.topAwayWinOddIncreased)}
                        {props?.list?.topAwayWinOdd?.toFixed(2)}
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default BestThreeWaysOdds;
