import React, { Component } from 'react';
import TranslationStore from 'components/stores/translation-store';
import MatchLineUpSoccer from 'components/block-elements/match-line-up-soccer/match-line-up-soccer';
import MatchTeamsList from 'components/block-elements/match-teams-list/match-teams-list';
import AbsentPlayers from 'components/block-elements/absent-players/absent-players';
import MatchHelper from 'components/helpers/match-helper';
import styles from './match-player-info.module.less';
import LineupWidget from '../lineup-widget/lineup-widget';
import SoccerSubstituteWidget from 'components/block-elements/soccer-substitute-wdiget/soccer-substitute-wdiget';
import BestThreeWaysOdds from 'components/block-elements/best-three-ways-odds/best-three-ways-odds';
import odds from 'static/odds.json' // как только запрос будет работать удалить это
import Cookies from 'universal-cookie';
import sporetEventService from 'services/sport-event-service';

export default class MatchPlayerInfo extends Component {

  constructor(props) {
    super(props);
    this.timeoutId = null;
    const cookies = new Cookies();
    cookies.set(props.matchId, props.alias, { path: '/', maxAge: 345600 });
    this.matchId = MatchHelper.getCleanSportRadarId(props.matchId);
    this.seasonId = MatchHelper.getCleanSportRadarId(props.seasonId);
    this.isFootballSportId = (props.sportId === "e:sport:1");
    this.state = {
      isPropsReady: false,
      teamsLineups: [],
      maxLines: 0,
      teams: [],
      homeTeamSubstitute: [],
      guestTeamSubstitute: [],
      homeTeamincidents: [],
      awayTeamIncidents: [],
      homeTeamMissingPlayers: [],
      awayTeamMissingPlayers: [],
      isLineupLoaded: false,
      homeTeamLineups: {
        mainPlayers: [],
        substitutesPlayers: [],
        formation: '',
        coach: ''
      },
      awayTeamLineups: {
        mainPlayers: [],
        substitutesPlayers: [],
        formation: '',
        coach: ''
      },
    };
  }

  isValidMatchLineUpSoccer(){
    return this.props.sportId == "e:sport:1";
  }

  isValidMatchLineUpHockey(){
    return this.props.sportId == "e:sport:5";
  }

  async componentDidMount() {
    let homeTeamMissingPlayers = [];
    let awayTeamMissingPlayers = [];

    await sporetEventService.getSportEventTeams(this.props.id)
      .then(res => {
        this.setState({ teams: res.data });
        homeTeamMissingPlayers = res.data?.find(tm => tm.competitorId == this.props.competitorHome).missingPlayers;
        awayTeamMissingPlayers = res.data?.find(tm => tm.competitorId == this.props.competitorAway).missingPlayers;
      });

    this.setState({ homeTeamMissingPlayers: homeTeamMissingPlayers, awayTeamMissingPlayers: awayTeamMissingPlayers})
    if(this.isValidMatchLineUpSoccer()) {
      await this.soccerLineUps();
    } else if (this.isValidMatchLineUpHockey()) {
      await this.hockeyLineups();
    }

    this.timeoutId = setInterval(() => {
      if(this.isValidMatchLineUpSoccer()) {
        this.soccerLineUps();
      } else if (this.isValidMatchLineUpHockey()) {
        this.hockeyLineups();
      }
    }, 15000);
}

componentWillUnmount() {
  clearInterval(this.timeoutId);
}

async soccerLineUps() {

  await sporetEventService.getMatchLineups(this.matchId)
    .then(res => {
      this.setState({
        homeTeamLineups: res.data.homeTeam,
        awayTeamLineups: res.data.awayTeam,
        isLineupLoaded: true
      })
    });
}

async hockeyLineups() {
  await sporetEventService.getMatchLineups(this.matchId)
  .then(res => {
    this.setState({
      homeTeamLineups: res.data.homeTeam,
      awayTeamLineups: res.data.awayTeam,
      isLineupLoaded: true,
      maxLines: res.data.homeTeamMaxLine
    })
  });
}

  isValidSubstitutesList(){
    return this.state.teams instanceof Array && 
             this.state.teams.length && 
             this.state.teams.every(team => team.substitutes instanceof Array && team.substitutes.length) ? true : false;
  }

  isValidPlayersList(){
    return this.state.teams instanceof Array && 
    this.state.teams.length 
     ? true : false;
  }

  render(){ 
    return (
    <>
    { this.props.sportId == "e:sport:1" &&
          <div style={{maxWidth:"500px", margin:"60px auto 0 auto"}} className={['block_max-width'].join(' ')}>
            {<h4 className={[styles.absentPlayerHeader].join(' ')}>Lineups</h4>}
            <MatchLineUpSoccer 
              {...this.props} 
              homeTeam={this.state.homeTeamLineups}
              awayTeam={this.state.awayTeamLineups}>
            </MatchLineUpSoccer>
          </div>
    }
    {  this.props.sportId == "e:sport:5" ? 
    <div style={{marginBottom: '30px'}}>
    <LineupWidget 
          {...this.props} 
          homeTeamId={this.props?.competitorIdHome}
          guestTeamId={this.props?.competitorIdAway}
          homeTeamName={this.props?.homeTeamDisplayName?.length > 0 ? this.props.homeTeamDisplayName : this.props.homeTeamName}
          guestTeamName={this.props?.awayTeamDisplayName?.length > 0 ? this.props.awayTeamDisplayName : this.props.awayTeamName}
          homeTeam={ this.state.homeTeamLineups.mainPlayers } 
          guestTeam={ this.state.awayTeamLineups.mainPlayers } 
          maxLines={this.state.maxLines}/>
      </div> : null
    }

    {
      (this.state.homeTeamLineups.substitutesPlayers.length > 0 && this.state.awayTeamLineups.substitutesPlayers.length > 0) &&
      <SoccerSubstituteWidget 
          key={this.state.homeTeamLineups.substitutesPlayers.length + this.state.awayTeamLineups.substitutesPlayers.length}
          {...this.props} 
          homeTeam={this.state.homeTeamLineups.substitutesPlayers}
          awayTeam={this.state.awayTeamLineups.substitutesPlayers}/>
    }

    {/* <BestThreeWaysOdds
      list={odds.threeWay}
    /> */}

    {
      this.isValidSubstitutesList() &&
      <div style={{maxWidth:"500px", margin:"0 auto"}} className={['block_max-width block__padded'].join(' ')}><MatchTeamsList {...this.props} teamListProperty={'substitutes'} listHeader={TranslationStore.getTranslation("match_player_info_substitues_header")}></MatchTeamsList></div>
    }
    {
      
      <div style={{maxWidth:"500px", margin:"0 auto"}} className={['block_max-width block__padded'].join(' ')}><section className={styles.absentPlayerSection}>

        <h2 className={['h4'].join(" ")}>{TranslationStore.getTranslation("match_player_info_wounded_absent_header")}</h2>
        <AbsentPlayers
          teams={this.state.teams}
          homeTeam={this.props.injuredPlayersHomeTeam instanceof Array ? this.props.injuredPlayersHomeTeam : [] }
          homeTeamName={this.props?.homeTeamDisplayName?.length > 0 ? this.props.homeTeamDisplayName : this.props.homeTeamName}
          homeTeamLogo={this.props?.homeTeamLogo}
          competitorIdHome={this.props.competitorIdHome}
          awayTeam={this.props.injuredPlayersAwayTeam instanceof Array ? this.props.injuredPlayersAwayTeam : []}
          awayTeamName={this.props?.awayTeamDisplayName?.length > 0 ? this.props.awayTeamDisplayName : this.props.awayTeamName}
          awayTeamLogo={this.props?.awayTeamLogo}
          competitorIdAway={this.props.competitorIdAway}
          sportId={this.props.sportId}
        />
      </section></div>
    }

    {
      this.isValidPlayersList() &&
      <div style={{maxWidth:"500px", margin:"0 auto"}} className={['block_max-width block__padded'].join(' ')}><MatchTeamsList {...this.props} teams={this.state.teams} teamListProperty={'players'} listHeader={TranslationStore.getTranslation("match_player_info_squads_header")}></MatchTeamsList></div>
    }
    </>)
  }
}